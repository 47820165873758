import { useEffect } from 'react';
import jMoment from 'moment-jalaali';
import JalaliUtils from '@date-io/jalaali';

// react-router-dom
import { BrowserRouter as Router } from 'react-router-dom';

// theme
import theme from 'themes';

// providers
import { StylesProvider } from 'providers/StylesProvider';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// components
import CssBaseline from '@material-ui/core/CssBaseline';
import Routes from 'Routes';

// hooks
import { useSettingsState } from 'providers/SettingsProvider';

// lib
import { getSettingsInStorage } from 'lib/Storage';

/**
 * @component ThemedApp
 * @summary this is the main application wrapper all pages will render withing
 * this component the main reason is to seprate theme from app and also use provider hooks within this component
 */

jMoment.loadPersian({ dialect: 'persian-modern', usePersianDigits: true });

function ThemedApp() {
  const [settings, setSettings] = useSettingsState();

  useEffect(() => {
    const localSettings = getSettingsInStorage();

    // check if localSettings exists and also we need to check for diffrence with global setting state value
    if (localSettings) {
      // if we found any settings we apply settings
      setSettings({ ...settings, ...localSettings });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme(settings.theme)}>
      <StylesProvider>
        <div dir="rtl">
          <SnackbarProvider
            autoHideDuration={2000}
            maxSnack={1}
            style={{ fontFamily: 'Shabnam, Poppins, sans-serif', direction: 'rtl' }}
          >
            <MuiPickersUtilsProvider utils={JalaliUtils} locale="fa">
              <CssBaseline />
              {/* base application */}
              <Router>
                <Routes />
              </Router>
              <div id="portal"></div>
              {/* end base application */}
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </div>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default ThemedApp;
