/**
 * we will use these values for spacing
 */

export const SPACING = 4;
export const SPACING_THIRD = 3;
export const SPACING_HALF = 2;
export const SPACING_LEAST = 1;
export const SPACING_DOUBLE = 8;
export const BORDER_RADIUS = 5;
