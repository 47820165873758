import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

import createTypography from './typography';
import spacing from './spacing';
import shadows from './shadows';
import createOverrides from './overrides';

// default theme
import * as ThemePaletteOptions from './palettes';

const theme = (paletteKey = 'defaultTheme') => {
  const palette = ThemePaletteOptions[paletteKey];
  const overrides = createOverrides(palette);
  const typography = createTypography(palette);

  return createMuiTheme({
    palette,
    direction: 'rtl',
    typography,
    spacing,
    overrides,
    shadows,
    props: {
      MuiButtonBase: {
        // disableRipple: true,
      },
    },
    shape: {
      borderRadius: 16,
    },
  });
};

export default theme;
