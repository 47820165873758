import { useContext, useState, createContext } from 'react';

const defaultValue = {
  theme: 'defaultTheme',
};

// initialize setting context
const SettingsContextValue = createContext(defaultValue);
const SettingsContextSetState = createContext(() => null);

function SettingsProvider({ children }) {
  const [setting, setSettings] = useState(defaultValue);
  return (
    <SettingsContextValue.Provider value={setting}>
      <SettingsContextSetState.Provider value={setSettings}>
        {children}
      </SettingsContextSetState.Provider>
    </SettingsContextValue.Provider>
  );
}

function useSettingsState() {
  const setting = useContext(SettingsContextValue);
  const setSettings = useContext(SettingsContextSetState);
  const state = [setting, setSettings];
  return state;
}

function useSettingsValue() {
  const setting = useContext(SettingsContextValue);
  return setting;
}

function useSettingsSetState() {
  const setSettings = useContext(SettingsContextSetState);
  return setSettings;
}

export { SettingsProvider, useSettingsState, useSettingsValue, useSettingsSetState };
