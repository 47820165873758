import { alpha } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';

const DARK_BG = 'rgb(22, 28, 36)';
const Primary = '#ff7500';
const GreenGradient = 'linear-gradient(135deg, rgb(0, 171, 85) 0%, rgb(0, 123, 85) 100%)';

const palette = createPalette({
  type: 'dark',
  primary: {
    main: Primary,
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#ffc208',
    contrastText: 'rgb(33, 43, 54)',
  },
  melon: {
    main: 'rgb(200, 250, 205)',
    contrastText: 'rgb(0, 82, 73)',
    dark: 'rgb(124 185 130)',
  },
  honey: {
    main: 'rgb(255, 247, 205)',
    contrastText: 'rgb(122, 79, 1)',
    dark: 'rgb(232 221 161)',
  },
  divider: alpha('#E6E9F4', 0.1),
  text: {
    primary: '#E6E9F4',
    secondary: '#9198A2',
    disabled: '#D7DBEC',
  },
  error: {
    main: '#f67282',
  },
  warning: {
    main: '#DFC57B',
  },
  success: {
    main: '#7ae6c3',
  },
  background: {
    default: DARK_BG,
    greenGr: GreenGradient,
    paper: '#212b36',
  },
  sideBar: {
    background: DARK_BG,
    color: '#FFF',
    footer: {
      background: '#1B2635',
      color: '#FFF',
    },
    actions: {
      selected: alpha(Primary, 0.2),
    },
  },

  appBar: {
    background: 'transparent',
    color: '#FFF',
  },
});

export default palette;
