import { login } from 'api/auth';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

function useLogin() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(login, {
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
    onSuccess: () => enqueueSnackbar('شما با موفقیت وارد پنل شدید.', { variant: 'success' }),
  });
}

export { useLogin };
