import { Fragment, useState } from 'react';
import clsx from 'clsx';

import { alpha, makeStyles } from '@material-ui/core/styles';

// react-router-dom
import { NavLink } from 'react-router-dom';

// constants
import { SPACING_HALF } from 'constants/spacing';

// components
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';

// icons
import DownArrowIcon from 'components/shared/icons/RightArrow';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import navIcons from 'constants/navigation';

const useStyles = makeStyles((theme) => ({
  arrow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: alpha(theme.palette.sideBar.color, 0.5),
    marginLeft: theme.spacing(SPACING_HALF),
  },
  arrowButton: {
    transition: theme.transitions.create(['transform']),
    transform: 'rotate(180deg)',
  },
  arrowDown: {
    transform: 'rotate(270deg)',
  },
  listItem: {
    height: '50px',
    transition: theme.transitions.create(['background', 'color']),
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    '&.active': {
      background: theme.palette.sideBar.actions.selected,
      borderRight: `4px solid ${theme.palette.primary.main}`,
    },
    '&.active $listItemText': {
      color: theme.palette.primary.main,
    },
    '&.active $listItemIcon': {
      color: theme.palette.primary.main,
    },
  },
  listItemText: {
    color: theme.palette.sideBar.color,
  },
  listItemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(SPACING_HALF),
    color: alpha(theme.palette.sideBar.color, 0.5),
  },
  navigationList: {
    marginLeft: theme.spacing(SPACING_HALF),
  },
}));

/**
 * @component NavigationItem
 */
function NavigationItemCore({ text, items, disabled, to = '#', icon }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen((prevState) => !prevState);
  };

  const upArrowClasses = clsx(classes.arrowButton, open && classes.arrowDown);

  const renderItemContent = (
    <Fragment>
      {navIcons[text] ? (
        <ListItemIcon className={classes.listItemIcon}>{navIcons[text]}</ListItemIcon>
      ) : (
        <ListItemIcon className={classes.listItemIcon}>
          <FiberManualRecordIcon style={{ fontSize: 8 }} />
        </ListItemIcon>
      )}
      <ListItemText color="inherit" className={classes.listItemText} primary={text} />
    </Fragment>
  );

  return (
    <Fragment>
      {items ? (
        <ListItem disabled={disabled} className={classes.listItem} button onClick={onClick}>
          {renderItemContent}
          <div className={classes.arrow}>
            <DownArrowIcon className={upArrowClasses} fontSize="small" />
          </div>
        </ListItem>
      ) : (
        <ListItem
          className={classes.listItem}
          component={NavLink}
          disabled={disabled}
          to={to}
          button
          onClick={onClick}
          isActive={(match, location) => {
            if (
              to === location.pathname ||
              match ||
              (to === '/customers' && location.pathname === '/')
            )
              return true;
          }}
        >
          {renderItemContent}
        </ListItem>
      )}

      {items && (
        <Collapse in={open} unmountOnExit>
          <div className={classes.navigationList}>
            <List>
              {items.map((item, key) => (
                <NavigationItemCore key={key} {...item} />
              ))}
            </List>
          </div>
        </Collapse>
      )}
    </Fragment>
  );
}

export default NavigationItemCore;
