import SvgIcon from '@material-ui/core/SvgIcon';

/**
 * @icon Calendar
 * @param {SvgIconProps}
 */
function Calendar(props) {
  return (
    <SvgIcon
      {...props}
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m16.8125 2.83333h-1.1459v-.91667c0-.50599-.4097-.91666-.9166-.91666s-.9167.41067-.9167.91666v.91667h-7.33331v-.91667c0-.50599-.40975-.91666-.91667-.91666-.50691 0-.91666.41067-.91666.91666v.91667h-1.14583c-1.39058 0-2.52083 1.13025-2.52083 2.52083v10.31244c0 1.5162 1.23383 2.75 2.74999 2.75h4.58333c.50691 0 .91666-.4106.91666-.9166s-.40975-.9167-.91666-.9167h-4.58333c-.50599 0-.91666-.4116-.91666-.9167v-7.33328h14.66667c0 .506.4097.91666.9166.91666s.9167-.41066.9167-.91666v-2.97916c0-1.39058-1.1302-2.52083-2.5208-2.52083z" />
      <path d="m17.0413 11.0834c-3.2853 0-5.9583 2.673-5.9583 5.9583s2.673 5.9583 5.9583 5.9583 5.9583-2.673 5.9583-5.9583-2.673-5.9583-5.9583-5.9583zm2.7555 4.9545-2.9791 3.4375c-.1669.1925-.4061.3062-.66.3163-.011 0-.022 0-.033 0-.243 0-.4758-.0963-.6481-.2686l-1.6042-1.6042c-.3584-.3584-.3584-.9377 0-1.2961.3584-.3585.9378-.3585 1.2962 0l.9084.9084 2.3338-2.6932c.3318-.3831.9112-.4226 1.2934-.0926.3823.331.4235.9103.0926 1.2925z" />
    </SvgIcon>
  );
}

export default Calendar;
