import axios from 'lib/axios';
import {
  removeAuthCookie,
  removeDivFilterInStorage,
  removeDivisionInStorage,
  removeNavigationInStorage,
  removeUserInStorage,
} from 'lib/Storage';

const login = async (username, password) => {
  const { data } = await axios.post('/admin/login', username, password);
  return data;
};

const logout = () => {
  removeAuthCookie();
  removeDivFilterInStorage();
  removeDivisionInStorage();
  removeUserInStorage();
  removeNavigationInStorage();
  // eslint-disable-next-line no-restricted-globals
  location.reload();
};

export { login, logout };
