import { getAllAdmins } from 'api/admin';
import { getProfile } from 'api/profile';
import { getDivisionInStorage } from 'lib/Storage';
import { useDivisionSetSate } from 'providers/DivisionProvider';
import { useUserState } from 'providers/UserProvider';
import { useQuery } from 'react-query';

function useAdmins() {
  return useQuery('admins', getAllAdmins);
}

function useProfile() {
  const [user, setUser] = useUserState();
  const setDivision = useDivisionSetSate();
  const localDivision = getDivisionInStorage();

  return useQuery('profile', getProfile, {
    onSuccess: (data) => {
      setUser({ ...user, ...data?.admin });
      if (localDivision) {
        setDivision({ ...localDivision, all: data?.divisions });
      } else {
        setDivision({ selected: data?.divisions[0], all: data?.divisions });
      }
    },
  });
}
export { useAdmins, useProfile };
