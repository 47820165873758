import { lazy, Suspense } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import ProgressBar from 'react-topbar-progress-indicator';
import { alpha, useTheme } from '@material-ui/core/styles';

// auth screens
import Auth from 'components/Auth/screens/Auth';
// import Otp from 'components/Auth/screens/Otp';
// import CompleteProfile from 'components/Auth/screens/CompleteProfile';
// import ForgetPassword from 'components/Auth/screens/ForgetPassword';

// templates
import DefaultTemplate from 'templates/DefaultTemplate';

// providers
import { useNavigationDrawerSetState } from 'providers/NavigationDrawerProvider';

// lib
import AdminRoute from 'lib/AdminRoute';

// LazyComponents
const Services = lazy(() => import('components/Services'));
const ServiceDetails = lazy(() => import('components/Services/screens/ServiceDetails'));
const CreateService = lazy(() => import('components/Services/screens/CreateService'));
const DivisionDetails = lazy(() => import('components/Divisions/screens/DivisionDetails'));
const CreateDivision = lazy(() => import('components/Divisions/screens/CreateDivision'));
const CreateBill = lazy(() => import('components/Tools/screens/CreateBill'));
// const SellerLanding = lazy(() => import('components/Products'));
// const CreateProduct = lazy(() => import('components/Products/screens/CreateProduct'));
// const Checkout = lazy(() => import('components/Products/screens/Checkout'));
const Orders = lazy(() => import('components/Orders'));
const Customers = lazy(() => import('components/Customers'));
const CustomerDetails = lazy(() => import('components/Customers/screens/CustomerDetails'));
const CreateCustomer = lazy(() => import('components/Customers/screens/CreateCustomer'));
// const NotFound = lazy(() => import('components/NotFound'));
const Profile = lazy(() => import('components/Profile'));
const Settings = lazy(() => import('components/Settings'));
const Invoices = lazy(() => import('components/Invoices'));
const CustomerInvoice = lazy(() => import('components/Invoices/screens/CustomerInvoice'));
const FinalInvoiceDetails = lazy(() =>
  import('components/Invoices/screens/FinalInvoiceDetails')
);
const AddCredit = lazy(() => import('components/Tools/screens/AddCredit'));
const ConsumeCredit = lazy(() => import('components/Tools/screens/ConsumeCredit'));

const Costs = lazy(() => import('components/Costs'));
const CreateCost = lazy(() => import('components/Costs/screens/CreateCost'));
const Personnel = lazy(() => import('components/Personnel'));

/**
 * @component Routes
 */
function Routes() {
  const setNavigationOpen = useNavigationDrawerSetState();
  const history = useHistory();
  const theme = useTheme();
  history.listen(() => {
    setNavigationOpen(false);
    window.scrollTo(0, 0);
  });

  ProgressBar.config({
    barColors: {
      0: alpha(theme.palette.primary.main, 0.7),
      '1.0': theme.palette.primary.main,
    },
    shadowBlur: 5,
  });

  return (
    <Switch>
      {/* Authentication Routes */}
      <Route exact component={Auth} path="/auth" />
      {/* <Route exact component={ForgetPassword} path="/auth/forget-password" />
      <Route exact component={Otp} path="/auth/otp" />
      <Route exact component={CompleteProfile} path="/auth/complete-profile" /> */}
      {/* Authentication Routes */}

      <Route>
        <DefaultTemplate>
          <Suspense fallback={<ProgressBar />}>
            <Switch>
              {/* Admin Routes */}

              <Route component={Invoices} exact path={'/invoices'} />
              <Route component={FinalInvoiceDetails} exact path={'/invoices/final/:id'} />
              <Route component={CustomerInvoice} exact path={'/invoices/:id'} />
              <AdminRoute component={Customers} exact path={'(/|/customers)'} />
              <AdminRoute component={Personnel} exact path={'/personnel'} />
              <AdminRoute component={Costs} exact path={'/costs'} />
              <AdminRoute component={CreateCost} exact path={'/costs/new'} />
              <AdminRoute component={CreateCustomer} exact path={'/customers/new'} />
              <AdminRoute component={CustomerDetails} exact path={'/customers/:id'} />
              <AdminRoute component={Services} exact path={'/services'} />
              <AdminRoute component={CreateService} exact path={'/services/new'} />
              <AdminRoute component={ServiceDetails} exact path={'/services/:id'} />
              <AdminRoute component={CreateDivision} exact path={'/divisions/new'} />
              <AdminRoute component={DivisionDetails} exact path={'/divisions/:id'} />
              <AdminRoute component={CreateBill} exact path={'/tools/create-bill'} />
              <AdminRoute component={AddCredit} exact path={'/tools/add-credit'} />
              <AdminRoute component={ConsumeCredit} exact path={'/tools/consume-credit'} />
              <AdminRoute component={Profile} exact path={'/profile'} />
              <AdminRoute component={Settings} exact path={'/settings'} />
              <AdminRoute component={Orders} exact path={'/orders'} />

              {/* Admin Routes */}

              {/* DM Routes */}
              {/* <Route component={SellerLanding} exact path={'/products'} />
                      <Route component={Checkout} exact path={'/products/checkout'} />
                      <Route component={CreateProduct} exact path={'/products/create'} /> */}
              {/* DM Routes */}

              {/* <Route component={NotFound} /> */}
            </Switch>
          </Suspense>
        </DefaultTemplate>
      </Route>
    </Switch>
  );
}

export default Routes;
