import { makeStyles } from '@material-ui/core';
import { SPACING_HALF, SPACING_LEAST } from 'constants/spacing';

export const DRAWER_WIDTH = 280;

const useStyles = makeStyles((theme) => ({
  logoSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(SPACING_HALF),
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },

  drawerPaper: {
    overflow: 'hidden',
    width: DRAWER_WIDTH,
    background: theme.palette.sideBar.background,
  },
  content: {
    height: '100%',
    color: theme.palette.sideBar.color,
    display: 'flex',
    flexDirection: 'column',
  },

  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },

  userBoxText: {
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(SPACING_LEAST),
  },
  sideBarHeaderPaper: {
    display: 'flex',
    cursor: 'pointer',
    justifySelf: 'center',
    alignItems: 'center',
    height: 95,
    padding: theme.spacing(SPACING_HALF),
    margin: theme.spacing(SPACING_HALF),
  },
  owner: {
    marginLeft: theme.spacing(SPACING_LEAST),
  },
  list: {
    overflow: 'auto',
    flex: 1,
    '&::-webkit-scrollbar': {
      width: '0px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'none',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'none',
    },
  },
}));

export default useStyles;
