import { useContext, useState, createContext } from 'react';

// initialize navigationDrawer context
const NavigationDrawerContextValue = createContext(false);
const NavigationDrawerContextSetState = createContext(() => null);

function NavigationDrawerProvider({ children }) {
  const [navigationDrawer, setNavigationDrawer] = useState(false);
  return (
    <NavigationDrawerContextValue.Provider value={navigationDrawer}>
      <NavigationDrawerContextSetState.Provider value={setNavigationDrawer}>
        {children}
      </NavigationDrawerContextSetState.Provider>
    </NavigationDrawerContextValue.Provider>
  );
}

function useNavigationDrawerState() {
  const navigationDrawer = useContext(NavigationDrawerContextValue);
  const setNavigationDrawer = useContext(NavigationDrawerContextSetState);
  const state = [navigationDrawer, setNavigationDrawer];
  return state;
}

function useNavigationDrawerValue() {
  const navigationDrawer = useContext(NavigationDrawerContextValue);
  return navigationDrawer;
}

function useNavigationDrawerSetState() {
  const setNavigationDrawer = useContext(NavigationDrawerContextSetState);
  return setNavigationDrawer;
}

export {
  NavigationDrawerProvider,
  useNavigationDrawerState,
  useNavigationDrawerValue,
  useNavigationDrawerSetState,
};
