import MuiFormControlLabel from './MuiFormControlLabel';
import MuiTableCell from './MuiTableCell';

const createOverrides = (palette) => {
  return {
    MuiFormControlLabel: MuiFormControlLabel(palette),
    MuiTableCell,
    MuiCssBaseline: {
      '@global': {
        a: {
          textDecoration: 'none',
          color: palette.text.primary,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#212b36',
        color: palette.common.white,
        borderRadius: 8,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
        fontSize: 14,
        fontWeight: 600,
      },
    },
    // MuiTextField: {
    //   root: {
    //     '& .MuiOutlinedInput-root': {
    //       '& fieldset': {
    //         borderRadius: 10,
    //       },
    //     },
    //   },
    // },
    MuiTab: {
      root: {
        minHeight: 32,
      },
      wrapper: {
        flexDirection: 'row',
        alignItems: 'flex-end',
      },
    },
  };
};

export default createOverrides;
