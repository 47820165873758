import axios from 'axios';

// api
import { getAuthCookie, removeAuthCookie } from './Storage';

export const HTTP_STATUS = {
  GONE: 410,
  Conflict: 409,
  Forbidden: 403,
  NotFound: 404,
  BadRequest: 400,
  NotAuthorized: 401,
  TooManyRequests: 429,
  ServerInternal: 500,
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

// Request interceptor for API calls
instance.interceptors.request.use(
  async (config) => {
    const keys = getAuthCookie();
    config.headers = keys?.adminToken
      ? {
          ...(keys && { admintoken: keys.adminToken }),
        }
      : { admintoken: `${getAuthCookie()}` };

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const responseInterceptor = instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === HTTP_STATUS.NotAuthorized) {
      removeAuthCookie();
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
    // Reject promise if usual error
    instance.interceptors.response.eject(responseInterceptor);

    /*
     * When response code is 401, try to refresh the token.
     * Eject the interceptor so it doesn't loop in case
     * token refresh causes the 401 response
     */

    return Promise.reject(error);
  }
);

export default instance;
