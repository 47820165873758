import { makeStyles } from '@material-ui/core/styles';

// providers
import { useNavigationDrawerSetState } from 'providers/NavigationDrawerProvider';

// components
import MuiAppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Badge from '@material-ui/core/Badge';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from 'components/shared/Typography';
import Tooltip from '@material-ui/core/Tooltip';

// icons
import MenuIcon from 'components/shared/icons/Menu';
import ShutDownIcon from 'components/shared/icons/ShutDown';
import BellIcon from 'components/shared/icons/Bell';

// constants
import { DRAWER_WIDTH } from '../NavigationDrawer/styles';

// api
import { logout } from 'api/auth';

// hooks
import { useUserValue } from 'providers/UserProvider';
import { useIsAdmin } from 'providers/UserProvider';

const useStyles = makeStyles((theme) => ({
  appBar: ({ isAdmin }) => {
    return {
      boxShadow: 'none',
      backdropFilter: 'blur(6px)',
      [theme.breakpoints.up('md')]: {
        width: isAdmin ? `calc(100% - ${DRAWER_WIDTH}px)` : '100%',
        marginLeft: DRAWER_WIDTH,
      },
      background: theme.palette.appBar.background,
    };
  },
  toolbar: theme.mixins.toolbar,
  wrapper: {
    flex: 1,
  },
}));

/**
 * @component AppBar
 */
function AppBar() {
  const user = useUserValue();
  const isAdmin = useIsAdmin();
  const classes = useStyles({ isAdmin });
  const setMobileOpen = useNavigationDrawerSetState();

  const onMenuClick = () => {
    setMobileOpen(true);
  };

  return (
    <MuiAppBar className={classes.appBar} elevation={0} color="inherit">
      <Toolbar>
        {isAdmin ? (
          <div className={classes.wrapper}>
            <Hidden mdUp implementation="js">
              <IconButton color="inherit" onClick={onMenuClick}>
                <MenuIcon />
              </IconButton>
            </Hidden>
          </div>
        ) : (
          <div className={classes.wrapper}>
            {user?.about ? (
              <Typography fontWeight={700} variant="h4" locale="en">
                {user?.about} 💅
              </Typography>
            ) : (
              <CircularProgress size={25} />
            )}
          </div>
        )}
        <Tooltip title="اعلان ها">
          <Badge color="secondary" variant="dot" overlap="circular">
            <IconButton>
              <BellIcon />
            </IconButton>
          </Badge>
        </Tooltip>

        <Tooltip title="خروج">
          <IconButton onClick={() => logout()}>
            <ShutDownIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </MuiAppBar>
  );
}

export default AppBar;
