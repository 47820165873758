import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// constants
import { SPACING, SPACING_HALF, SPACING_LEAST } from 'constants/spacing';
import { DRAWER_WIDTH } from 'components/Layouts/NavigationDrawer/styles';

// components
import AppBar from 'components/Layouts/AppBar';
import NavigationDrawer from 'components/Layouts/NavigationDrawer';
import ThemeDrawer from 'components/Layouts/ThemeDrawer';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

// icons
import SettingsIcon from 'components/shared/icons/Settings';

// lib
import withAuth from 'lib/withAuth';

// hooks
import { useIsAdmin } from 'providers/UserProvider';
import { useProfile } from 'hooks/admin';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    backgroundColor: theme.palette.common.white,
  },
  toolbar: theme.mixins.toolbar,
  content: ({ isAdmin }) => {
    return {
      width: isAdmin ? `calc(100% - ${DRAWER_WIDTH}px)` : '100%',
      padding: theme.spacing(SPACING, SPACING_HALF),
      height: '100%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: theme.spacing(SPACING, SPACING_LEAST),
      },
    };
  },
  themeButton: {
    position: 'fixed',
    bottom: theme.spacing(SPACING_HALF),
    right: theme.spacing(SPACING_HALF),
    zIndex: theme.zIndex.speedDial,
  },
}));

/**
 * @component DefaultTemplate
 */
function DefaultTemplate({ children }) {
  const isAdmin = useIsAdmin();
  const classes = useStyles({ isAdmin });
  const [openThemeDrawer, setOpenThemeDrawer] = useState(false);

  const { status } = useProfile();
  console.log(status);

  return (
    <div className={classes.root}>
      <AppBar />
      {isAdmin && <NavigationDrawer />}
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.themeButton}>
          <Tooltip title="تنظیمات">
            <Fab color="primary" size="small" onClick={() => setOpenThemeDrawer(true)}>
              <SettingsIcon color="inherit" />
            </Fab>
          </Tooltip>
        </div>
        <ThemeDrawer
          open={openThemeDrawer}
          setOpen={setOpenThemeDrawer}
          onClose={() => setOpenThemeDrawer(false)}
        />
        {children}
      </div>
    </div>
  );
}

export default withAuth(DefaultTemplate);
