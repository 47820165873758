import { useContext, useState, createContext, useEffect } from 'react';

const defualtValue = {};

// initialize user context
const UserContextValue = createContext(defualtValue);
const UserContextSetState = createContext(() => null);

function UserProvider({ children, user = defualtValue }) {
  const [_user, setUser] = useState(user);

  useEffect(() => {
    setUser(user);
  }, [user]);
  return (
    <UserContextValue.Provider value={_user}>
      <UserContextSetState.Provider value={setUser}>{children}</UserContextSetState.Provider>
    </UserContextValue.Provider>
  );
}

function useUserState() {
  const user = useContext(UserContextValue);
  const setUser = useContext(UserContextSetState);
  const state = [user, setUser];
  return state;
}

function useUserValue() {
  const user = useContext(UserContextValue);
  return user;
}

function useIsAdmin() {
  const user = useContext(UserContextValue);
  if (user === undefined) {
    throw new Error('user must be used within a CountProvider');
  }
  const isAdmin = user?.type === 'superAdmin';
  return isAdmin;
}

function useUserSetState() {
  const setUser = useContext(UserContextSetState);
  return setUser;
}

export { UserProvider, useUserState, useUserValue, useUserSetState, useIsAdmin };
