import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { setSettingInStorage } from 'lib/Storage';
import * as themes from 'themes/palettes';
import { useSettingsState } from 'providers/SettingsProvider';

// constants
import { SPACING_DOUBLE, SPACING_HALF } from 'constants/spacing';

// components
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ThemeButton from './ThemeButton';
import Divider from '@material-ui/core/Divider';
import SelectDivision from 'components/shared/SelectDivision';
import LoadingButton from 'components/shared/LoadingButton';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// icons
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Create';
import Cancel from '@material-ui/icons/Cancel';

// hooks
import { useDivisionValue } from 'providers/DivisionProvider';
import { useIsAdmin } from 'providers/UserProvider';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 258,
  },
  drawerWrapper: {
    // padding: theme.spacing(SPACING_HALF, 0),
  },
  drawerTitle: {
    ...theme.typography.body1,
  },
  divider: {
    margin: theme.spacing(SPACING_HALF, 0),
  },
  selectTheme: {
    marginBottom: theme.spacing(SPACING_DOUBLE),
  },
  actionsRow: {
    marginTop: theme.spacing(SPACING_HALF),
    alignItems: 'center',
  },
  closeButton: {
    marginBottom: theme.spacing(SPACING_HALF),
  },
}));

const themeList = [
  {
    key: 'defaultTheme',
    label: 'اصلی',
    primaryColor: themes.defaultTheme.background.default,
    secondaryColor: themes.defaultTheme.sideBar.background,
  },
  {
    key: 'darkBlueTheme',
    label: 'تاریک',
    primaryColor: themes.darkBlueTheme.background.default,
  },
];

/**
 * @component ThemeDrawer
 */
function ThemeDrawer({ setOpen, onClose, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const isAdmin = useIsAdmin();
  const [settings, setSettings] = useSettingsState();
  const division = useDivisionValue();

  const editHandler = () => {
    history.push(`/divisions/${division?.selected?._id}`);
    setOpen(false);
  };
  const createHandler = () => {
    history.push(`/divisions/new`);
    setOpen(false);
  };

  return (
    <Drawer
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      anchor="right"
      disablePortal
      {...rest}
    >
      <div className={classes.drawerWrapper}>
        <div className={classes.closeButton}>
          <Tooltip title="بستن">
            <IconButton onClick={onClose}>
              <Cancel />
            </IconButton>
          </Tooltip>
        </div>
        <Container>
          <Typography className={classes.drawerTitle}>
            پوسته مورد نظر را انتخاب کنید
          </Typography>
          <Divider className={classes.divider} />
          <Grid container spacing={SPACING_HALF} className={classes.selectTheme}>
            {themeList.map(({ key, label, primaryColor, secondaryColor }) => {
              return (
                <Grid item xs={6} key={key}>
                  <ThemeButton
                    selected={settings.theme === key}
                    name={label}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    onClick={() => {
                      const newSettings = { ...settings, theme: key };
                      setSettings(newSettings);
                      setSettingInStorage(newSettings);
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Typography className={classes.drawerTitle}>بخش مورد نظر را انتخاب کنید</Typography>
          <Divider className={classes.divider} />
          <SelectDivision disableClearable />
          {isAdmin ? (
            <div className={classes.actionsRow}>
              <LoadingButton
                fullWidth
                variant="contained"
                startIcon={<AddIcon />}
                color="primary"
                onClick={createHandler}
              >
                بخش جدید
              </LoadingButton>

              <LoadingButton
                startIcon={<EditIcon />}
                style={{ marginTop: 8 }}
                fullWidth
                variant="contained"
                color="secondary"
                onClick={editHandler}
              >
                ویرایش بخش
              </LoadingButton>
            </div>
          ) : null}
        </Container>
      </div>
    </Drawer>
  );
}

export default ThemeDrawer;
