import { useHistory } from 'react-router-dom';

// providers
import { useNavigationDrawerState } from 'providers/NavigationDrawerProvider';

// components
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import NavigationItemCore from './NavigationItem';
import Tooltip from '@material-ui/core/Tooltip';

// styles
import useStyles from './styles';

// hooks
import { useUserValue } from 'providers/UserProvider';
import { getNavigationInStorage } from 'lib/Storage';

// icons
import Cancel from '@material-ui/icons/Cancel';

/**
 * @component NavigationDrawer
 */
function NavigationDrawer() {
  const classes = useStyles();
  const history = useHistory();
  const [mobileOpen, setMobileOpen] = useNavigationDrawerState();

  const user = useUserValue();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const renderDrawerContent = (
    <div className={classes.content}>
      <Hidden mdUp>
        <div className={classes.closeButton}>
          <Tooltip title="بستن">
            <IconButton onClick={handleDrawerToggle}>
              <Cancel />
            </IconButton>
          </Tooltip>
        </div>
      </Hidden>

      <Paper
        className={classes.sideBarHeaderPaper}
        elevation={0}
        onClick={() => history.push('/profile')}
      >
        <Avatar>{user?.username?.slice(0, 1)}</Avatar>
        <div className={classes.owner}>
          <ListItemText
            classes={{ secondary: classes.userBoxText }}
            primary={user?.username}
            secondary={user?.about}
          />
        </div>
      </Paper>

      <Divider variant="middle" light />

      <List className={classes.list}>
        {getNavigationInStorage()?.map((item, key) => {
          return <NavigationItemCore key={key} {...item} />;
        })}
      </List>
    </div>
  );

  return (
    <div className={classes.drawer}>
      <Hidden mdUp implementation="js">
        <SwipeableDrawer
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          elevation={2}
          variant="temporary"
          onOpen={handleDrawerToggle}
          disablePortal
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {renderDrawerContent}
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown implementation="js">
        <Drawer
          elevation={2}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {renderDrawerContent}
        </Drawer>
      </Hidden>
    </div>
  );
}

export default NavigationDrawer;
