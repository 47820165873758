import SvgIcon from '@material-ui/core/SvgIcon';

/**
 * @icon Tools
 * @param {SvgIconProps}
 */
function Tools(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25pt"
      height="25pt"
      viewBox="0 0 25 25"
      version="1.1"
    >
      <g id="surface1">
        <path d="M 17.1875 1.5625 C 13.746094 1.5625 10.9375 4.371094 10.9375 7.8125 C 10.9375 8.367188 11.085938 8.867188 11.21875 9.363281 L 10.839844 9.742188 L 7.8125 6.707031 L 7.8125 4.253906 L 7.445312 4.027344 L 3.796875 1.746094 L 1.746094 3.757812 L 4.25 7.8125 L 6.707031 7.8125 L 9.742188 10.839844 L 4.039062 16.539062 C 2.824219 17.753906 2.824219 19.746094 4.039062 20.960938 C 5.253906 22.175781 7.246094 22.175781 8.460938 20.960938 L 12.5 16.917969 L 16.539062 20.960938 C 17.753906 22.175781 19.746094 22.175781 20.960938 20.960938 C 22.175781 19.746094 22.175781 17.753906 20.960938 16.539062 L 18.359375 13.941406 C 21.246094 13.390625 23.4375 10.851562 23.4375 7.8125 C 23.4375 6.769531 23.15625 5.796875 22.71875 4.949219 L 22.222656 4.003906 L 21.472656 4.761719 L 18.117188 8.113281 L 16.886719 6.886719 L 20.996094 2.769531 L 20.050781 2.28125 C 19.203125 1.84375 18.230469 1.5625 17.1875 1.5625 Z M 17.1875 3.125 C 17.542969 3.125 17.847656 3.296875 18.183594 3.382812 L 14.679688 6.886719 L 18.117188 10.320312 L 21.625 6.816406 C 21.703125 7.152344 21.875 7.457031 21.875 7.8125 C 21.875 10.414062 19.789062 12.5 17.1875 12.5 C 16.648438 12.5 16.136719 12.390625 15.625 12.207031 L 15.160156 12.046875 L 7.355469 19.855469 C 6.738281 20.472656 5.761719 20.472656 5.144531 19.855469 C 4.527344 19.238281 4.527344 18.261719 5.144531 17.644531 L 12.953125 9.839844 L 12.792969 9.375 C 12.609375 8.863281 12.5 8.351562 12.5 7.8125 C 12.5 5.210938 14.585938 3.125 17.1875 3.125 Z M 4.015625 3.722656 L 6.25 5.121094 L 6.25 6.121094 L 6.121094 6.25 L 5.125 6.25 L 3.730469 3.996094 Z M 15.636719 13.78125 C 15.796875 13.824219 15.953125 13.859375 16.113281 13.902344 L 19.855469 17.644531 C 20.472656 18.261719 20.472656 19.238281 19.855469 19.855469 C 19.238281 20.472656 18.261719 20.472656 17.644531 19.855469 L 13.605469 15.8125 Z M 15.636719 13.78125 " />
      </g>
    </SvgIcon>
  );
}

export default Tools;
