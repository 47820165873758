import { v4 as uuidv4 } from 'uuid';
import { addCommas, digitsFaToEn } from 'persian-tools2';

export function checkPersian(str) {
  const p = /[\u0600-\u06FF\s]+/;

  return p.test(str.replace(' ', ''));
}

export function addUniqueKey(array) {
  return array?.map((item) => {
    return { __id: uuidv4(), ...item };
  });
}

/**
 * convert a flat list of nodes into tree array
 * @param array list of nodes as flat array
 * @param parentChildData data to locate node parents
 */
export function listToTree(array, parentChildData) {
  let root = [];
  array.forEach((el) => {
    // Use our mapping to locate the parent element in our data array
    const parent = parentChildData(el, array);

    if (!parent) {
      root.push(el);
      return;
    }
    // Add our current el to its parent's `children` array
    parent.children = [...(parent.children || []), el];
  });

  return root;
}

export function normalizeArray(array) {
  return array.filter(Boolean);
}

export function textToMaterialColor(palette, text, returnParent = false) {
  if (!text) return palette.text.primary;

  const hasDot = text.indexOf('.') !== -1;
  if (hasDot) {
    const color = text.split('.');
    return returnParent ? palette[color[0]] : palette[color[0]][color[1]];
  } else return !returnParent ? palette[text].main : palette[text];
}

export function toFarsiNumber(n, useComma = false) {
  const farsiDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
  const text = useComma ? addCommas(n) : n;
  const result = text?.toString().replace(/\d/g, (x) => farsiDigits[x]);
  return result;
}

export function toNationalCode(phone) {
  const enNumber = digitsFaToEn(phone);
  const result = enNumber?.toString().replace(/^0+/, '').replace(/^98+/, '');
  return `0${result}`;
}

export function isEmpty(obj) {
  return obj === undefined || null ? true : Object.keys(obj).length === 0;
}

export function arrayUniqueKey(array, key) {
  return [...new Map(array.map((item) => [item[key], item])).values()];
}
