import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';

// components
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Input from 'components/shared/Input';
import LoadingButton from 'components/shared/LoadingButton';
import Typography from 'components/shared/Typography';

// assets
import Logo from 'assets/images/logo.svg';

// constants
import { SPACING, SPACING_THIRD } from 'constants/spacing';

// hooks
import { useLogin } from 'hooks/auth';
import { useUserState } from 'providers/UserProvider';

// lib
import {
  getAuthCookie,
  setAuthCookie,
  setNavigationInStorage,
  setUserInStorage,
} from 'lib/Storage';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 'calc(64px + 5%)',
  },
  form: {
    width: '100%',
  },
  password: {
    paddingTop: theme.spacing(SPACING),
  },

  buttonWraper: {
    marginTop: theme.spacing(SPACING),
  },
  paper: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logo: {
    width: '100%',
    maxWidth: 100,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 65,
    },
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  logotype:{
    display:'block',
    position:'relative',
    marginBottom:'-5px',
    top:'105px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  }
}));

/**
 * @component Auth
 */
function Auth() {
  const classes = useStyles();
  const [user, setUser] = useUserState();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { username: '', password: '' } });
  const { mutate, isLoading } = useLogin();
  const history = useHistory();

  const onSubmit = (data) => {
    mutate(data, {
      onSuccess: (response) => {
        if (response) {
          if (response?.type === 'superAdmin') {
            setUser({ ...user });
            setUserInStorage({ isAdmin: true });
            setNavigationInStorage(response?.navigations);
          } else {
            setUser({ ...user });
          }
          setAuthCookie({ ...response });
        }
      },
    });
  };

  if (!!getAuthCookie()?.adminToken) history.push('/');

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <form noValidate onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <Grid container spacing={SPACING_THIRD} alignItems="center">
            <Grid item xs={12}>
            <Typography color="primary" className={classes.logotype}>کافه</Typography>
            <Typography color="primary" className={classes.logotype}>قاصدک</Typography>

              <div className={classes.logoWrapper}>

              
                
                <img src={Logo} alt="logo" className={classes.logo} />
                <Typography>لطفا اطلاعات خود را وارد کنید</Typography>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="username"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Input
                    label="نام کاربری"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={
                      errors?.username?.type === 'required' && (
                        <Typography variant="body2" component="span" color="error">
                          نام کاربری مورد نیاز است
                        </Typography>
                      )
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="password"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Input
                    label="کلمه عبور"
                    fullWidth
                    type="password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={
                      errors?.password?.type === 'required' && (
                        <Typography variant="body2" component="span" color="error">
                          کلمه عبور مورد نیاز است
                        </Typography>
                      )
                    }
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} className={classes.buttonWraper}>
              <LoadingButton
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                loading={isLoading}
              >
                ورود به پنل
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default Auth;
