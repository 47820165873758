import { makeStyles } from '@material-ui/core/styles';

import { useState, useEffect, forwardRef, useCallback } from 'react';

import clsx from 'clsx';

// components
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  input: ({ readOnly }) => ({
    pointerEvents: readOnly ? 'none' : 'auto',
  }),

  inputRoot: ({ height }) => {
    return {
      height: height,
    };
  },
  inputCore: {},
}));

/**
 * @component Input
 */
const Input = forwardRef(
  (
    {
      className,
      textColor,
      endAdornment,
      variant = 'outlined',
      value,
      height = 'auto',
      startAdornment,
      defaultValue,
      onChange = () => {},
      readOnly,
      inputOption,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles({ readOnly, height });

    const [_value, setValue] = useState(defaultValue);

    const onTextFieldChange = useCallback(
      (event) => {
        setValue(event.target.value);
        onChange(event.target.value);
      },
      [onChange]
    );

    useEffect(() => {
      setValue(value);
    }, [value]);

    return (
      <TextField
        InputProps={{
          readOnly,
          endAdornment: endAdornment,
          startAdornment: startAdornment,
          classes: { root: classes.inputRoot, input: classes.inputCore },
          ...inputOption,
        }}
        value={_value}
        onChange={onTextFieldChange}
        className={clsx(classes.input, className)}
        variant={variant}
        inputRef={ref}
        {...rest}
      />
    );
  }
);

export default Input;
