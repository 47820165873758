const shadows = [
  'none',
  `0 1px 4px rgba(21,34, 50, 0.08)`,
  `0 1px 8px rgba(21,34, 50, 0.08)`,
  `0 2px 16px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `0 2px 32px rgba(21,34, 50, 0.08)`,
  `rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px`,
];
export default shadows;
