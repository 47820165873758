import SvgIcon from '@material-ui/core/SvgIcon';

/**
 * @icon Service
 * @summary shut down icon from 'Simple Small' icon group wrapped with material svg icon
 * @param {SvgIconProps}
 */
function Service(props) {
  return (
    <SvgIcon {...props}>
      <path d="M 12.5 1.5625 C 7.527344 1.5625 4.847656 4.992188 4.847656 4.992188 C 4.632812 5.273438 4.632812 5.65625 4.847656 5.9375 L 9.375 11.980469 L 9.375 21.09375 C 9.375 21.527344 9.722656 21.875 10.15625 21.875 L 14.84375 21.875 C 15.277344 21.875 15.625 21.527344 15.625 21.09375 L 15.625 11.980469 L 20.160156 5.9375 C 20.367188 5.65625 20.367188 5.273438 20.148438 4.992188 C 20.148438 4.992188 17.472656 1.5625 12.5 1.5625 Z M 12.5 3.125 C 16.199219 3.125 17.988281 5 18.480469 5.566406 L 14.453125 10.9375 L 10.546875 10.9375 L 6.519531 5.566406 C 7.011719 5 8.800781 3.125 12.5 3.125 Z M 10.9375 12.5 L 14.0625 12.5 L 14.0625 20.3125 L 10.9375 20.3125 Z M 10.9375 12.5 " />
    </SvgIcon>
  );
}

export default Service;
