import SvgIcon from '@material-ui/core/SvgIcon';

/**
 * @icon Menu
 * @summary menu icon from 'Simple Small' icon group wrapped with material svg icon
 * @param {SvgIconProps}
 */
function Menu(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <g fill="currentColor">
        <circle cx="4" cy="12" r="1"></circle>
        <rect x="7" y="11" width="14" height="2" rx=".94" ry=".94"></rect>
        <rect x="3" y="16" width="18" height="2" rx=".94" ry=".94"></rect>
        <rect x="3" y="6" width="18" height="2" rx=".94" ry=".94"></rect>
      </g>
    </SvgIcon>
  );
}

export default Menu;
