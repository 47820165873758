import { useContext, useState, createContext, useEffect } from 'react';

const defaultValue = {
  selected: null,
  all: [],
};

// initialize setting context
const DivisionContextValue = createContext(defaultValue);
const DivisionContextSetState = createContext(() => null);

function DivisionProvider({ children, division = defaultValue }) {
  const [_division, setDivision] = useState(defaultValue);
  useEffect(() => {
    setDivision(division);
  }, [division]);
  return (
    <DivisionContextValue.Provider value={_division}>
      <DivisionContextSetState.Provider value={setDivision}>
        {children}
      </DivisionContextSetState.Provider>
    </DivisionContextValue.Provider>
  );
}

function useDivisionState() {
  const division = useContext(DivisionContextValue);
  const setDivision = useContext(DivisionContextSetState);
  const state = [division, setDivision];
  return state;
}

function useDivisionValue() {
  const division = useContext(DivisionContextValue);
  return division;
}

function useDivisionSetSate() {
  const setDivision = useContext(DivisionContextSetState);
  return setDivision;
}

export { DivisionProvider, useDivisionState, useDivisionValue, useDivisionSetSate };
