import { useIsAdmin } from 'providers/UserProvider';
import { Redirect, Route } from 'react-router-dom';
import { getUserInStorage } from './Storage';

export default function AdminRoute({ component: Component, ...rest }) {
  const isAdmin = useIsAdmin();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAdmin || getUserInStorage()?.isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect to="/invoices" />
        )
      }
    />
  );
}
