import DashboardIcon from 'components/shared/icons/Dashboard';
import UsersIcon from 'components/shared/icons/Users';
import OrdersIcon from 'components/shared/icons/Orders';
import ToolsIcon from 'components/shared/icons/Tools';
import Service from 'components/shared/icons/Service';
import Calendar from 'components/shared/icons/Calendar';
import Personnel from 'components/shared/icons/Personnel';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const navIcons = {
  مشتریان: <UsersIcon />,
  'فاکتور ها': <OrdersIcon />,
  تنظیمات: <DashboardIcon />,
  'هزینه ها': <MonetizationOnIcon />,
  'ابزار ها': <ToolsIcon />,
  پرسنل: <Personnel />,
  خدمات: <Service />,
  رزرو: <Calendar />,
};

export default navIcons;
