// components
import Autocomplete from '@material-ui/lab/Autocomplete';
import Input from '../Input';

// hooks
import { useDivisionState } from 'providers/DivisionProvider';

// lib
import { setDivisionInStorage } from 'lib/Storage';

/**
 * @component SelectDivision
 */
function SelectDivision({ value, readOnly, ...rest }) {
  const [division, setDivision] = useDivisionState();
  const changeDivisionHandler = (event, value) => {
    const newDivision = { ...division, selected: value };
    setDivision(newDivision);
    setDivisionInStorage(newDivision);
  };
  return (
    <Autocomplete
      disablePortal
      value={division?.selected}
      loading={!division?.all?.length}
      onChange={changeDivisionHandler}
      filterSelectedOptions
      disabled={readOnly}
      getOptionSelected={(option, value) => value?._id === option?._id}
      options={division?.all?.length ? division?.all : []}
      getOptionLabel={(option) => (option?.title ? option?.title : '')}
      renderInput={(params) => <Input label="انتخاب بخش" {...params} fullWidth />}
      {...rest}
    />
  );
}

export default SelectDivision;
