/**
 * @icon Users
 * @param {SvgIconProps}
 */
function Users() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="20"
      height="20"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        d="M9 11a4 4 0 1 0-4-4a4 4 0 0 0 4 4zm8 2a3 3 0 1 0-3-3a3 3 0 0 0 3 3zm4 7a1 1 0 0 0 1-1a5 5 0 0 0-8.06-3.95A7 7 0 0 0 2 20a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1"
        fill="currentColor"
      ></path>
    </svg>
  );
}

export default Users;
