import { alpha } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';

const TextColor = 'rgb(33, 43, 54)';
const BackGroundColor = '#F7F9FC';
const Primary = '#ff7500';
const GreenGradient = 'linear-gradient(135deg, rgb(0, 171, 85) 0%, rgb(0, 123, 85) 100%)';

const palette = createPalette({
  type: 'light',
  primary: {
    main: Primary,
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#ffc208',
    contrastText: 'rgb(33, 43, 54)',
  },
  melon: {
    main: 'rgb(200, 250, 205)',
    contrastText: 'rgb(0, 82, 73)',
    dark: 'rgb(124 185 130)',
  },
  honey: {
    main: 'rgb(255, 247, 205)',
    contrastText: 'rgb(122, 79, 1)',
    dark: 'rgb(232 221 161)',
  },
  divider: '#E6E9F4',
  text: {
    primary: '#233044',
    secondary: '#9198A2',
    disabled: '#D7DBEC',
  },
  error: {
    main: '#F0142F',
  },
  warning: {
    main: '#FFC700',
  },
  success: {
    main: '#21D59B',
  },
  background: {
    default: BackGroundColor,
    greenGr: GreenGradient,
    paper: '#FFFFFF',
  },
  sideBar: {
    background: BackGroundColor,
    color: TextColor,
    footer: {
      background: '#ffff',
      color: '#FFF',
    },
    actions: {
      selected: alpha(Primary, 0.2),
    },
  },
  appBar: {
    background: 'transparent',
    color: '#233044',
  },
});

export default palette;
