// providers
import { UserProvider } from 'providers/UserProvider';
import { SettingsProvider } from 'providers/SettingsProvider';
import { NavigationDrawerProvider } from 'providers/NavigationDrawerProvider';
import { QueryClient, QueryClientProvider } from 'react-query';

// components
import ThemedApp from 'ThemedApp';
import { DivisionProvider } from 'providers/DivisionProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <DivisionProvider>
          <SettingsProvider>
            <NavigationDrawerProvider>
              <ThemedApp />
            </NavigationDrawerProvider>
          </SettingsProvider>
        </DivisionProvider>
      </UserProvider>
    </QueryClientProvider>
  );
}

export default App;
