import SvgIcon from '@material-ui/core/SvgIcon';

/**
 * @icon Personnel
 * @param {SvgIconProps}
 */
function Personnel(props) {
  return (
    <SvgIcon {...props}>
      <path d="M 12.5 0 C 10.851562 0 9.5 1.351562 9.5 3 C 9.5 4.648438 10.851562 6 12.5 6 C 14.148438 6 15.5 4.648438 15.5 3 C 15.5 1.351562 14.148438 0 12.5 0 Z M 12.5 1 C 13.609375 1 14.5 1.890625 14.5 3 C 14.5 4.109375 13.609375 5 12.5 5 C 11.390625 5 10.5 4.109375 10.5 3 C 10.5 1.890625 11.390625 1 12.5 1 Z M 11 6.5 C 9.078125 6.5 7.5 8.078125 7.5 10 L 7.5 11.5 L 8.5 11.5 L 8.5 10 C 8.5 8.625 9.625 7.5 11 7.5 L 14 7.5 C 15.375 7.5 16.5 8.625 16.5 10 L 16.5 11.5 L 17.5 11.5 L 17.5 10 C 17.5 8.078125 15.921875 6.5 14 6.5 Z M 21 9.5 L 21 10 L 22 10 L 22 9.5 Z M 21.5 10 C 20.296875 10 19.28125 10.867188 19.046875 12 L 0.5 12 C 0.484375 12 0.46875 12 0.453125 12 C 0.195312 12.023438 -0.00390625 12.242188 0 12.5 L 0 14.5 C 0 14.773438 0.226562 15 0.5 15 L 1 15 L 1 24.5 C 1 24.773438 1.226562 25 1.5 25 L 23.5 25 C 23.773438 25 24 24.773438 24 24.5 L 24 15 L 24.5 15 C 24.773438 15 25 14.773438 25 14.5 L 25 12.5 C 25 12.226562 24.773438 12 24.5 12 L 23.953125 12 C 23.71875 10.867188 22.703125 10 21.5 10 Z M 21.5 11 C 22.148438 11 22.695312 11.421875 22.90625 12 L 20.09375 12 C 20.304688 11.421875 20.851562 11 21.5 11 Z M 1 13 L 24 13 L 24 14 L 1.59375 14 C 1.527344 13.984375 1.457031 13.984375 1.390625 14 L 1 14 Z M 2 15 L 23 15 L 23 24 L 2 24 Z M 2 15 " />
    </SvgIcon>
  );
}

export default Personnel;
